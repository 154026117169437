import { IconDefinition, IconName } from '@fortawesome/free-solid-svg-icons'

/**
 * Adds the Font Awesome V5 version of the fas-info icon to the project.
 */

const prefix = 'fas'
const iconName = 'info-v5'

const width = 192
const height = 512
const ligatures = [] as string[]
const unicode = 'f119'

// eslint-disable-next-line
const svgPathData = 'M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z'

const faOldInfo: IconDefinition = {
  prefix: prefix,
  iconName: iconName as IconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
}

export default faOldInfo
