import faOldInfo from '@/utilities/font-awesome/faOldInfo'
import faSkatteetaten from '@/utilities/font-awesome/faSkatteetaten'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquare, faComment, faMessage } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBalanceScale, faBars, faBuilding, faCalculator, faCar, faCaretDown, faCaretRight, faCheck, faCheckSquare, faChevronCircleLeft,
  faChevronCircleRight, faChevronDown, faChevronRight, faChevronUp, faCompress, faCopy, faDivide, faEdit, faEllipsisH, faEllipsisV, faEquals, faExchangeAlt, faExclamation, faExclamationCircle,
  faExclamationTriangle, faExpand, faExternalLinkAlt, faEye, faStoreAlt, faFileAlt, faHome, faInfo, faInfoCircle, faLink, faLinkSlash,
  faList, faListUl, faLongArrowAltLeft, faMailBulk, faMinus, faMinusCircle, faArrowUpRightFromSquare, faBox,
  faMoneyCheck, faPaperPlane, faPlayCircle, faPlus, faPlusCircle, faQuestion, faRocket, faSave, faSearch, faSignInAlt, faSignOutAlt, faTable, faTasks, faTimes, faTimesCircle, faToolbox, faTrash,
  faTrashAlt, faUndo, faUser, faWindowClose, faShield, faExpandAlt, faXmark, faCircleQuestion, faNoteSticky, faLightbulb, faLongArrowAltRight, faCog,
  faDownload, faWrench, faFilePdf, faFlagCheckered, faUserCog, faMedkit
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserCog,
  faFilePdf,
  faWrench,
  faDownload,
  faBox,
  faArrowUpRightFromSquare,
  faStoreAlt,
  faCog,
  faSkatteetaten,
  faOldInfo,
  faInfo,
  faQuestion,
  faListUl,
  faMailBulk,
  faXmark,
  faEllipsisH,
  faEllipsisV,
  faDivide,
  faExchangeAlt,
  faMinusCircle,
  faMinus,
  faEquals,
  faToolbox,
  faExpand,
  faExpandAlt,
  faCompress,
  faBalanceScale,
  faCar,
  faMoneyCheck,
  faTable,
  faPaperPlane,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPlus,
  faExternalLinkAlt,
  faList,
  faPlusCircle,
  faTimes,
  faUser,
  faChevronCircleRight,
  faChevronRight,
  faChevronCircleLeft,
  faCaretDown,
  faCaretRight,
  faRocket,
  faSave,
  faTasks,
  faTrash,
  faTrashAlt,
  faSquare,
  faShield,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faCheckSquare,
  faWindowClose,
  faTimesCircle,
  faSignOutAlt,
  faSignInAlt,
  faExclamation,
  faBars,
  faHome,
  faBuilding,
  faPlayCircle,
  faFileAlt,
  faUndo,
  faCalculator,
  faInfoCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faCopy,
  faEdit,
  faSearch,
  faLink,
  faLinkSlash,
  faCircleQuestion,
  faNoteSticky,
  faLightbulb,
  faMessage,
  faComment,
  faFlagCheckered,
  faMedkit
)
