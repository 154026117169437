import '@/plugins/input-mask/style/style.css'
import '@/utilities/font-awesome'
import '@fontsource/fira-code/latin.css'
import '@fontsource/fira-sans/latin.css'
import '@fontsource/open-sans/latin.css'
// eslint-disable-next-line import/no-unresolved
import 'uno.css'
import '@tritt/components/dist/theme.css'
import { createPinia } from 'pinia'
import './css/tailwind.css'

const appNode = '#app'

const mountApp = async () => {
  const pinia = createPinia()

  const loadingApp = await import('./loadingApp')
  const loadingAppInstance = await loadingApp.createApp(pinia)
  loadingAppInstance.mount(appNode)

  const mainApp = await import('./mainApp')
  const mainAppInstance = await mainApp.createApp(pinia)

  loadingAppInstance.unmount()
  mainAppInstance.mount(appNode)
}

mountApp()
