import { IconDefinition, IconName } from '@fortawesome/free-solid-svg-icons'

/**
 * Adds a font-awesome compatible version of the Skatteetaten logo to the project.
 */

const prefix = 'fas'
const iconName = 'skatteetaten'

const width = 102
const height = 70
const ligatures = [] as string[]
const unicode = 'f119'

// eslint-disable-next-line
const svgPathData = 'M0 41.4044c0 15.2844 22.7156 27.6744 50.7324 27.6744 28.0203 0 50.7466-12.39 50.7466-27.6744 0-10.5504-10.817-19.7245-26.748-24.3893L50.7739 41.4044V27.5136L74.731 3.36975C66.8953 1.15122 58.7861.0476567 50.6425.091594l.007 13.645306C22.6707 13.7611 0 26.1373 0 41.4044Z'

const faSkatteetaten: IconDefinition = {
  prefix: prefix,
  iconName: iconName as IconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
}

export default faSkatteetaten
